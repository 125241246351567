import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { slide as BurgerMenu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as ArchivedMapsIcon } from "../../assets/icons/archived-maps.svg";
import { ReactComponent as BoosterIcon } from "../../assets/icons/booster_2.svg";
// import { ReactComponent as BagBoosterIcon } from "../../assets/icons/bag-booster.svg";
import { ReactComponent as CommunityIcon } from "../../assets/icons/community.svg";
import { ReactComponent as FAQIcon } from "../../assets/icons/faq.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/messages.svg";
import { ReactComponent as MyMapsIcon } from "../../assets/icons/my-maps.svg";
import { ReactComponent as MyProfileAndSettingsIcon } from "../../assets/icons/my-profile-and-settings.svg";
import MediaCenterIcon from "../../assets/images/play-icon.png";
import StatusIcon from "../../assets/images/status-icon.png";
import {
  setBadgesPopupOpen,
  setBoosterQuestionsPopup,
  setViewBoosterQuestionsPopupOpen,
  setWelcomePopupsOpen,
} from "../../store/app-settings/app-settings-slice";
import { resetAccessToken } from "../../store/auth/auth-slice";
import { changeProfile } from "../../store/profile/profile-slice";
import Api from "../../utils/api";
import { NativeEvents } from "../../utils/enums";
import { ProfileImageUpload } from "../profile-image-upload-old/profile-image-upload-old";
import "./menu.scss";

const Menu = () => {
  const { t, i18n } = useTranslation("Menu");

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadRef = useRef();
  const profile = useSelector((state) => state.profileReducer.profile);
  const {
    hideMenuItems,
    boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen,
    badgesPopupOpen,
  } = useSelector((state) => ({
    hideMenuItems: state.appSettingsReducer.hideMenuItems,
    boosterQuestionsPopup: state.appSettingsReducer.boosterQuestionsPopup,
    viewBoosterQuestionsPopupOpen:
      state.appSettingsReducer.viewBoosterQuestionsPopupOpen,
    badgesPopupOpen: state.appSettingsReducer.badgesPopupOpen,
  }));

  const [open, setOpen] = useState(false);
  const [userProfileImagePreviewSrc, setUserProfileImagePreviewSrc] =
    useState("");
  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.Settings.ImagePath)
        setUserProfileImagePreviewSrc(
          `${process.env.REACT_APP_BASE_IMAGE_URL}/${profile.Settings.ImagePath}`
        );
      else if (userProfileImagePreviewSrc) setUserProfileImagePreviewSrc("");
    }
  }, [profile]);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted)
      setCameraAndMediaLibraryPermissionGranted(true);
  };

  const openPage = () => {
    if (boosterQuestionsPopup.open) {
      dispatch(setBoosterQuestionsPopup({ open: false, editMode: false }));
    }
    if (viewBoosterQuestionsPopupOpen) {
      dispatch(setViewBoosterQuestionsPopupOpen(false));
    }
    setOpen(false);
  };

  const openBoosterQuestions = () => {
    dispatch(setViewBoosterQuestionsPopupOpen(true));
    setOpen(false);
  };

  const openBadgsePopup = () => {
    dispatch(setBadgesPopupOpen(true));
    setOpen(false);
  };

  const logout = async () => {
    try {
      const response = await Api.post("/auth/logout");
      if (response.data) {
        dispatch(setWelcomePopupsOpen(false));
        dispatch(resetAccessToken());
        Cookies.remove("refreshToken");
        dispatch(changeProfile(null));
      }
    } catch (err) {
      console.error(err);
      dispatch(resetAccessToken());
      Cookies.remove("refreshToken");
    }
  };

  const changeProfileImage = async (imagesrc) => {
    dispatch(
      changeProfile({
        ...profile,
        Settings: {
          ...profile.Settings,
          ImagePath: imagesrc,
        },
      })
    );
  };

  return (
    <div className={isIOS ? "ios-menu" : "menu"}>
      <BurgerMenu
        right={i18n.language === "he"}
        isOpen={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <span
          style={i18n.language === "he" ? { right: "220px" } : { left: "8px" }}
          className="menu-text-header"
        >
          {t("menu")}
        </span>

        <div className="menu-header">
          {
            <img
              className="profile-image"
              src={
                userProfileImagePreviewSrc ||
                require("../../assets/icons/user.svg").default
              }
            />
          }

          <div className="greeting flex">
            <div>{t("hello")},</div>
            <div className="name">
              {profile && `${profile.FirstName} ${profile.LastName}`}
            </div>
            <hr />
          </div>
        </div>

        {!hideMenuItems && profile && (
          <>
            {profile.Admin && (
              <div className="user-menu-item-header">
                <span>{t("user")}</span>
                <hr />
              </div>
            )}

            <div className="item-button-container flex">
              <MyProfileAndSettingsIcon className="item-icon" />
              <NavLink
                to="profile-settings"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("settings")}
              </NavLink>
            </div>

            <div className="item-button-container flex">
              <BoosterIcon className="item-icon fa-item-icon" />
              <span className={"bm-item"} onClick={openBoosterQuestions}>
                {t("boosters")}
              </span>
            </div>

            <div className="item-button-container flex">
              <MyMapsIcon className="item-icon" />
              <NavLink
                to="maps"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("maps")}{" "}
              </NavLink>
            </div>

            <div className="item-button-container flex">
              <div className="fa-item-icon flex">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <NavLink
                to="pathways-schedule"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("reminders")}
              </NavLink>
            </div>

            <div className="item-button-container flex">
              <ArchivedMapsIcon className="item-icon" />
              <NavLink
                to="archived-maps"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("archived-maps")}
              </NavLink>
            </div>

            <div className="item-button-container flex messages-icon">
              <MessagesIcon className="item-icon" />
              <NavLink
                to="messages"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("messages")}
              </NavLink>
            </div>

            <div className="item-button-container flex">
              <CommunityIcon className="item-icon" />
              <NavLink
                to="community"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("hope-for-all")}
              </NavLink>
            </div>

            {profile?.Phone === "+972587897623" && (
              <div className="item-button-container flex">
                <FAQIcon className="item-icon" />
                <NavLink
                  to="Courses"
                  className={({ isActive }) =>
                    "bm-item" + (isActive ? " active" : "")
                  }
                  onClick={openPage}
                >
                  {t("Courses")}
                </NavLink>
              </div>
            )}
            {/* <div className="item-button-container flex">
              <FAQIcon className="item-icon" />
              <NavLink
                to="FAQ"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("FAQ")}
              </NavLink>
            </div> */}
            {
              /* (profile?.Phone === "+972547997155" ||
              profile?.Phone === "+972547898398" ||
              profile?.Phone === "+972525813751" ||
              profile?.Phone === "+972537536392" ||
              profile?.Phone === "+972525291333" ||
              profile?.Phone === "+972545603120" ||
              profile?.Phone === "+972587897623" ||
              profile?.Phone === "+972528506862") */ true && (
                <div className="item-button-container flex">
                  <img src={StatusIcon} className="item-icon" />
                  <span className={"bm-item"} onClick={openBadgsePopup}>
                    {t("my-status")}
                  </span>
                </div>
              )
            }
            {
              /* (profile?.Phone === "+972547997155" ||
              profile?.Phone === "+972547898398" ||
              profile?.Phone === "+972525813751" ||
              profile?.Phone === "+972537536392" ||
              profile?.Phone === "+972525291333" ||
              profile?.Phone === "+972545603120" ||
              profile?.Phone === "+972587897623" ||
              profile?.Phone === "+972528506862") */ true && (
                <div className="item-button-container-image flex">
                  <img src={MediaCenterIcon} className="item-icon-image" />
                  <NavLink
                    to="user-media-center"
                    className={({ isActive }) =>
                      "bm-item" + (isActive ? " active" : "")
                    }
                    onClick={openPage}
                  >
                    {t("media-center")}
                  </NavLink>
                </div>
              )
            }

            <div className="item-button-container flex">
              <FAQIcon className="item-icon" />
              {/* <NavLink
                to="contact-us"
                className={({ isActive }) =>
                  "bm-item" + (isActive ? " active" : "")
                }
                onClick={openPage}
              >
                {t("ContactUs")}
              </NavLink> */}
              <a href="mailto:hopetimize@lifesdoor.org" className="bm-item">
                {t("ContactUs")}
              </a>
            </div>
            {profile.Admin && (
              <div className="user-menu-item-header">
                <span>{t("admin")}</span>
                <hr />
              </div>
            )}

            {profile.Admin && (
              <div>
                <div className="item-button-container flex">
                  <div className="fa-item-icon flex">
                    <FontAwesomeIcon icon={faFileLines} />
                  </div>
                  <NavLink
                    to="admin-dashboard"
                    className={({ isActive }) =>
                      "bm-item" + (isActive ? " active" : "")
                    }
                    onClick={openPage}
                  >
                    {t("dashboard")}
                  </NavLink>
                </div>
                {
                  /*(profile?.Phone === "+972547997155" ||
                  profile?.Phone === "+972547898398" ||
                  profile?.Phone === "+972525813751" ||
                  profile?.Phone === "+972537536392" ||
                  profile?.Phone === "+972525291333" ||
                  profile?.Phone === "+972545603120" ||
                  profile?.Phone === "+972587897623" ||
                  profile?.Phone === "+972528506862") */ true && (
                    <div className="item-button-container-image flex">
                      <img src={MediaCenterIcon} className="item-icon-image" />
                      <NavLink
                        to="media-center"
                        className={({ isActive }) =>
                          "bm-item" + (isActive ? " active" : "")
                        }
                        onClick={openPage}
                      >
                        {t("media-center")}
                      </NavLink>
                    </div>
                  )
                }
              </div>
            )}
          </>
        )}
        <div className="item-button-container flex logout-item">
          <div className="logout-container flex">
            <LogoutIcon />
            <NavLink to="login" className={"bm-item"} onClick={logout}>
              {t("logout")}
            </NavLink>
          </div>
        </div>
      </BurgerMenu>
    </div>
  );
};

export default Menu;
