import React, { useState, useEffect } from "react";
import { UserContentBox } from "./user-content-box/user-content-box";
import { useTranslation } from "react-i18next";
import "./media-center.scss";

// redux
import { useDispatch } from "react-redux";
import { getContentsForUser } from "../../../store/media-center/media-center-slice";
import {
  bookmarkUserContent,
  viewUserContent,
} from "../../../store/profile/profile-slice";
import {
  closeToastMessage,
  setShowLoader,
  setTitle,
  setTitleIcon,
  setViewBoosterQuestionsPopupOpen,
  showToastMessage,
} from "../../../store/app-settings/app-settings-slice";
import { SearchModal } from "./search-modal/search-modal";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";
import SearchIcon from "../../../assets/images/search-icon.png";
import { AskForMore } from "./ask-for-more/ask-for-more";
import BoostersIcon from "../../../assets/images/save-as-booster.png";
import { useSelector } from "react-redux";
import { setLinkFromBoosters } from "../../../store/media-center/media-center-slice";
import { toast } from "react-toastify";

export const UserMediaCenter = ({ mediaType = "MediaSpace" }) => {
  const { linkFromBoosters } = useSelector((state) => ({
    linkFromBoosters: state.mediaCenterReducer.linkFromBoosters,
  }));
  const { t, i18n } = useTranslation("MediaCenter");

  const [contents, setContents] = useState([]);
  const [filteredContents, setFilteredContents] = useState([]);

  const [showOnlyBookmarked, setShowOnlyBookmarked] =
    useState(linkFromBoosters);
  const [showAskForMore, setShowAskForMore] = useState(false);

  const [strFilter, setStrFilter] = useState("");
  const [filterByTitle, setFilterByTitle] = useState(false);
  const [filterByDescription, setFilterByDescription] = useState(false);
  const [filterByTags, setFilterByTags] = useState(false);
  const [filterByCategories, setFilterByCategories] = useState(false);

  const [filterByArticle, setFilterByArticle] = useState(false);
  const [filterByVideo, setFilterByVideo] = useState(false);
  const [filterByPodcast, setFilterByPodcast] = useState(false);
  const [filterBySong, setFilterBySong] = useState(false);
  const [filterByTip, setFilterByTip] = useState(false);
  const [filterByOther, setFilterByOther] = useState(false);
  const [langFilter, setLangFilter] = useState({ value: "", label: "" });

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showAdvancedfilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    if (showOnlyBookmarked && !showAskForMore) {
      dispatch(setTitle(t("Main.boosters")));
      dispatch(setTitleIcon(true));
    } else if (showAskForMore) {
      dispatch(setTitle(t("Main.ask-for-more")));
    } else {
      dispatch(setTitle(t("Main.title")));
    }
  }, [i18n, showOnlyBookmarked, showAskForMore]);

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      setShowLoader(true);
      dispatch(getContentsForUser()).then((res) => {
        console.table(res.payload);
        const filteredLocationContents = filterContentsLocation(res.payload);
        console.log({ filteredLocationContents });
        const sortedContents = sortContents(filteredLocationContents);

        setContents(sortedContents);
        setFilteredContents(
          sortedContents.filter(
            (content) => !showOnlyBookmarked || content.Bookmarked
          )
        );
        setShowLoader(false);
      });
    } catch {
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    if (showOnlyBookmarked) {
      dispatch(setLinkFromBoosters(false));
    }
  }, [showOnlyBookmarked]);

  useEffect(() => {
    if (linkFromBoosters) {
      setShowOnlyBookmarked(true);
    }
  }, [linkFromBoosters]);

  const filterByType = (
    filterByArticleChecked = false,
    filterByVideoChecked = false,
    filterByPodcastChecked = false,
    filterBySongChecked = false,
    filterByTipChecked = false,
    filterByOtherChecked = false,
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) =>
        (!filterByArticleChecked &&
          !filterByVideoChecked &&
          !filterByPodcastChecked &&
          !filterBySongChecked &&
          !filterByTipChecked &&
          !filterByOtherChecked) ||
        (filterByArticleChecked && content.Content.Type === "Article") ||
        (filterByVideoChecked && content.Content.Type === "Video") ||
        (filterByPodcastChecked && content.Content.Type === "Podcast") ||
        (filterBySongChecked && content.Content.Type === "Song") ||
        (filterByTipChecked && content.Content.Type === "Tip") ||
        (filterByOtherChecked && content.Content.Type === "Other")
    );
  };

  const filterByBookmarked = (
    onlyBookmarkedChecked = true,
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) => content.Bookmarked || !onlyBookmarkedChecked
    );
  };

  const filterByText = (
    str,
    filterByTitleCheked = false,
    filterByDescriptionChecked = false,
    filterByTagsCheked = false,
    filterByCategoriesChecked = false,
    AlreadyFilteredContents = contents
  ) => {
    if (str === "") {
      return AlreadyFilteredContents;
    } else {
      if (showAdvancedfilters) {
        return AlreadyFilteredContents.filter(
          (content) =>
            (!filterByTitleCheked || content.Content.Title.includes(str)) &&
            (!filterByDescriptionChecked ||
              content.Content.Description.includes(str)) &&
            (!filterByTagsCheked ||
              content.Content.Tags.Tags.some((tag) => tag.includes(str)) ||
              content.Content.Tags.All) &&
            (!filterByCategoriesChecked ||
              content.Content.Categories.All ||
              content.Content.Categories.Categories.some((category) =>
                category.Name.includes(str)
              ))
        );
      } else {
        return AlreadyFilteredContents.filter(
          (content) =>
            content.Content.Title?.includes(str) ||
            content.Content.Description.includes(str) ||
            content.Content.Tags.All ||
            content.Content.Categories.All ||
            content.Content.Tags.Tags.some((tag) => tag.includes(str)) ||
            content.Content.Categories.Categories.some((category) =>
              category.Name?.includes(str)
            )
        );
      }
    }
  };

  const filterByLang = (
    langFilterTemp = { value: "", label: "" },
    AlreadyFilteredContents = contents
  ) => {
    return AlreadyFilteredContents.filter(
      (content) =>
        content.Content.Languages.Languages.includes(langFilterTemp.value) ||
        langFilterTemp.value == ""
    );
  };

  const filterBase = (
    str = "",
    filterByTitleCheked = false,
    filterByDescriptionChecked = false,
    filterByTagsCheked = false,
    filterByCategoriesChecked = false,
    onlyBookmarkedChecked = false,
    filterByArticleChecked = false,
    filterByVideoChecked = false,
    filterByPodcasedChecked = false,
    filterBySongChecked = false,
    filterByTipChecked = false,
    filterByOtherChecked = false,
    langFilterTemp = { value: "", label: "" },
    contentsToFilter = contents
  ) => {
    let filteredContentsTemp = contentsToFilter;
    filteredContentsTemp = filterByText(
      str,
      filterByTitleCheked,
      filterByDescriptionChecked,
      filterByTagsCheked,
      filterByCategoriesChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByBookmarked(
      onlyBookmarkedChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByType(
      filterByArticleChecked,
      filterByVideoChecked,
      filterByPodcasedChecked,
      filterBySongChecked,
      filterByTipChecked,
      filterByOtherChecked,
      filteredContentsTemp
    );

    filteredContentsTemp = filterByLang(langFilterTemp, filteredContentsTemp);

    return filteredContentsTemp;
  };

  const sortContents = (tempContents) => {
    if (tempContents && tempContents.length > 0) {
      let resArr = [];
      resArr.push(tempContents[0]);
      for (let i = 1; i < tempContents.length; i++) {
        var onStart = true;
        for (let j = 0; j < resArr.length; j++) {
          if (tempContents[i].DisplayOrder > resArr[j].DisplayOrder) {
            resArr.splice(j + 1, 0, tempContents[i]);
            onStart = false;
            break;
          }
        }
        if (onStart) {
          resArr.splice(0, 0, tempContents[i]);
        }
      }
      return resArr;
    } else {
      return [];
    }
  };

  const filterContentsLocation = (tempContents) => {
    if (!tempContents || !tempContents.length > 0) return [];
    if (mediaType == "MediaSpace") return tempContents;
    return tempContents.filter(({ Content }) => Content.Location == mediaType);
  };

  return (
    <div className="user-media-center-container">
      {!showAskForMore && showOnlyBookmarked && (
        <div className="open-booster-popup">
          <img
            src={BoostersIcon}
            onClick={() => {
              dispatch(setViewBoosterQuestionsPopupOpen(true));
            }}
          />
        </div>
      )}
      {showSearchModal && (
        <SearchModal
          close={() => {
            setShowSearchModal(false);
          }}
          filterBase={filterBase}
          showOnlyBookmarked={showOnlyBookmarked}
          setFilteredContents={setFilteredContents}
          showAdvancedfilters={showAdvancedfilters}
          setShowAdvancedFilters={setShowAdvancedFilters}
          strFilter={strFilter}
          setStrFilter={setStrFilter}
          filterByTags={filterByTags}
          setFilterByTags={setFilterByTags}
          filterByCategories={filterByCategories}
          setFilterByCategories={setFilterByCategories}
          filterByTitle={filterByTitle}
          setFilterByTitle={setFilterByTitle}
          filterByDescription={filterByDescription}
          setFilterByDescription={setFilterByDescription}
          filterByArticle={filterByArticle}
          setFilterByArticle={setFilterByArticle}
          filterByVideo={filterByVideo}
          setFilterByVideo={setFilterByVideo}
          filterByPodcast={filterByPodcast}
          setFilterByPodcast={setFilterByPodcast}
          filterBySong={filterBySong}
          setFilterBySong={setFilterBySong}
          filterByTip={filterByTip}
          setFilterByTip={setFilterByTip}
          filterByOther={filterByOther}
          setFilterByOther={setFilterByOther}
          langFilter={langFilter}
          setLangFilter={setLangFilter}
          setShowAskForMore={setShowAskForMore}
        />
      )}
      {!showAskForMore && (
        <div
          className="search-icon-container"
          onClick={() => {
            setShowSearchModal(true);
          }}
        >
          <img src={SearchIcon} className="search-icon" />
          <h3>{t("Common:search")}</h3>{" "}
        </div>
      )}
      <div
        className="bookmark-filter"
        style={mediaType != "MediaSpace" ? { display: "none" } : {}}
      >
        <h4
          style={
            !showOnlyBookmarked && !showAskForMore ? { color: "blue" } : {}
          }
          onClick={() => {
            let filteredContentsTemp = filterBase(
              strFilter,
              filterByTitle,
              filterByDescription,
              filterByTags,
              filterByCategories,
              false,
              filterByArticle,
              filterByVideo,
              filterByPodcast,
              filterBySong,
              filterByTip,
              filterByOther,
              langFilter
            );
            setShowOnlyBookmarked(false);
            setShowAskForMore(false);
            setFilteredContents(filteredContentsTemp);
          }}
        >
          {mediaType == "MediaSpace" ? t("Main.media") : t("Courses:title")}
        </h4>
        <h4
          style={showOnlyBookmarked && !showAskForMore ? { color: "blue" } : {}}
          onClick={() => {
            let filteredContentsTemp = filterBase(
              strFilter,
              filterByTitle,
              filterByDescription,
              filterByTags,
              filterByCategories,
              true,
              filterByArticle,
              filterByVideo,
              filterByPodcast,
              filterBySong,
              filterByTip,
              filterByOther,
              langFilter
            );
            setShowOnlyBookmarked(true);
            setShowAskForMore(false);
            setFilteredContents(filteredContentsTemp);
          }}
        >
          {t("Main.boosters")}
        </h4>
        <h4
          style={showAskForMore ? { color: "blue" } : {}}
          onClick={() => {
            setShowAskForMore(true);
          }}
        >
          {t("Main.ask-for-more")}
        </h4>
      </div>

      <div className="quick-remove-search-container">
        {filterByArticle && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                console.log("A");
                setFilterByArticle(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  false,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(
                  sortContents(filterContentsLocation(filteredContentsTemp))
                );
              }}
            />
            <h4>{t("Main.articles")}</h4>
          </div>
        )}
        {filterByVideo && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByVideo(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  false,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>{t("Main.videos")}</h4>
          </div>
        )}
        {filterByPodcast && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByPodcast(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  false,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>{t("Main.podcasts")}</h4>
          </div>
        )}
        {filterBySong && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterBySong(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  false,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>{t("Main.songs")}</h4>
          </div>
        )}
        {filterByTip && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByTip(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  false,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>{t("Main.tips")}</h4>
          </div>
        )}
        {filterByOther && !showAskForMore && (
          <div className="quick-remove-search-item">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setFilterByOther(false);
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  false,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>{t("Main.others")}</h4>
          </div>
        )}
        {langFilter.value && !showAskForMore && (
          <div className="quick-remove-search-item">
            {langFilter.label}{" "}
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setLangFilter({ value: "", label: "" });
                let filteredContentsTemp = filterBase(
                  strFilter,
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  { value: "", label: "" }
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
          </div>
        )}
        {strFilter && !showAskForMore && (
          <div className="quick-remove-search-str">
            <XIcon
              className="quick-remove-search-item-x-icon"
              onClick={() => {
                setStrFilter("");
                let filteredContentsTemp = filterBase(
                  "",
                  filterByTitle,
                  filterByDescription,
                  filterByTags,
                  filterByCategories,
                  showOnlyBookmarked,
                  filterByArticle,
                  filterByVideo,
                  filterByPodcast,
                  filterBySong,
                  filterByTip,
                  filterByOther,
                  langFilter
                );
                setFilteredContents(filteredContentsTemp);
              }}
            />
            <h4>
              {strFilter.length < 15
                ? strFilter
                : strFilter.substring(0, 12) + "..."}
            </h4>
          </div>
        )}
      </div>
      <div className="text-filters">
        <div className="filters-checkbox"></div>
      </div>
      {!showAskForMore ? (
        <div className="user-contents-container">
          {filteredContents.map((content) => (
            <UserContentBox
              key={content.id}
              item={content.Content}
              id={content.id}
              bookmarked={content.Bookmarked}
              viewed={content.Viewed}
              showOnlyBookmarked={showOnlyBookmarked}
              bookmarkUserContent={(content, id) => {
                const data = {
                  Content: content,
                  Id: id,
                };
                try {
                  dispatch(setShowLoader(true));
                  dispatch(bookmarkUserContent(data)).then(() => {
                    dispatch(getContentsForUser()).then((res) => {
                      const sortedContents = sortContents(
                        filterContentsLocation(res.payload)
                      );
                      let filteredContentsTemp = filterBase(
                        strFilter,
                        filterByTitle,
                        filterByDescription,
                        filterByTags,
                        filterByCategories,
                        showOnlyBookmarked,
                        filterByArticle,
                        filterByVideo,
                        filterByPodcast,
                        filterBySong,
                        filterByTip,
                        filterByOther,
                        langFilter,
                        sortedContents
                      );
                      setContents(sortedContents);
                      setFilteredContents(filteredContentsTemp);
                      dispatch(setShowLoader(false));
                    });
                  });
                } catch {
                  dispatch(setShowLoader(false));
                }
              }}
              viewUserContent={(content, id) => {
                const data = {
                  Content: content,
                  Id: id,
                };
                try {
                  dispatch(setShowLoader(true));
                  dispatch(viewUserContent(data)).then(() => {
                    dispatch(getContentsForUser()).then((res) => {
                      const sortedContents = sortContents(
                        filterContentsLocation(res.payload)
                      );
                      let filteredContentsTemp = filterBase(
                        strFilter,
                        filterByTitle,
                        filterByDescription,
                        filterByTags,
                        filterByCategories,
                        showOnlyBookmarked,
                        filterByArticle,
                        filterByVideo,
                        filterByPodcast,
                        filterBySong,
                        filterByTip,
                        filterByOther,
                        langFilter,
                        sortedContents
                      );
                      setContents(sortedContents);
                      setFilteredContents(filteredContentsTemp);
                    });
                    dispatch(setShowLoader(false));
                  });
                } catch {
                  dispatch(setShowLoader(false));
                }
              }}
            />
          ))}
        </div>
      ) : (
        <AskForMore
          returnToMedia={() => {
            let filteredContentsTemp = filterBase(
              strFilter,
              filterByTitle,
              filterByDescription,
              filterByTags,
              filterByCategories,
              false,
              filterByArticle,
              filterByVideo,
              filterByPodcast,
              filterBySong,
              filterByTip,
              filterByOther,
              langFilter
            );
            setShowOnlyBookmarked(false);
            setShowAskForMore(false);
            setFilteredContents(filteredContentsTemp);
          }}
        />
      )}
    </div>
  );
};
