import React, { useEffect } from "react";
import "./Courses.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTitle } from "../../../store/app-settings/app-settings-slice";
import { UserMediaCenter } from "../media-center/media-center";
export default function Courses() {
  const { t, i18n } = useTranslation("Courses");
  const dispatch = useDispatch();
  const initialCoursesPage = () => {
    console.log(t("Courses:title"));
    dispatch(setTitle(t("title")));
  };
  useEffect(initialCoursesPage, [t, dispatch, i18n]);
  return (
    <div>
      <UserMediaCenter mediaType="Courses" />
    </div>
  );
}
