import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTitle } from "../../store/app-settings/app-settings-slice";

export default function ContactUs() {
  const { t, i18n } = useTranslation("ContactUs");
  const dispatch = useDispatch();
  const initialCoursesPage = () => {
    console.log(t("title"));
    dispatch(setTitle(t("title")));
  };
  useEffect(initialCoursesPage, [t, dispatch, i18n]);
  return (
    <div>
      <a href="mailto:michal@lifesdoor.org">{t("title")}</a>
    </div>
  );
}
