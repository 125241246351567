import { useSelector } from "react-redux";
import { Title } from "../title/title";
import "./app-header.scss";
import { useTranslation } from "react-i18next";
import statusIcon from "../../assets/images/name-status-icon.png";
import { useEffect } from "react";
import { useState } from "react";
import Api from "../../utils/api";
import { useDispatch } from "react-redux";
import {
  setLevelUpPopupOpen,
  setTitleIcon,
} from "../../store/app-settings/app-settings-slice";
import ExpertDiamond from "../../assets/images/expert-diamond.png";
import MasterDiamond from "../../assets/images/master-diamond.png";
import LegendDiamond from "../../assets/images/legend-diamond.png";
import ChampionDiamond from "../../assets/images/champion-diamond.png";
import { getUserPoints } from "../../store/profile/profile-slice";

export const AppHeader = () => {
  const { t, i18n } = useTranslation("Menu");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const dispatch = useDispatch();

  const [points, setPoints] = useState(0);

  const profile = useSelector((state) => state.profileReducer.profile);
  const map = useSelector((state) => state.mapReducer.map);
  const smallTitle = useSelector(
    (state) => state.appSettingsReducer.smallTitle
  );
  const title = useSelector((state) => state.appSettingsReducer.title);
  const titleIcon = useSelector((state) => state.appSettingsReducer.titleIcon);

  console.log(title);

  const getProfilePoints = async () => {
    dispatch(getUserPoints()).then((res) => {
      if (
        points > 0 &&
        ((points < 1001 && res.payload > 1000) ||
          (points < 2501 && res.payload > 2500) ||
          (points < 5001 && res.payload > 5000))
      ) {
        dispatch(setLevelUpPopupOpen(true));
      }
      setPoints(res.payload);
    });
  };

  useEffect(() => {
    if (profile) {
      getProfilePoints();
    }
  }, [profile && profile.Points, map]);

  useEffect(() => {
    if (title !== t("MediaCenter:Main.boosters")) {
      dispatch(setTitleIcon(false));
    }
  }, [title]);

  return (
    <div className="app-header" style={isIOS ? { height: "100px" } : {}}>
      <span
        className={"user-name" + (!profile ? " hidden" : "")}
        style={isIOS ? { transform: "translateY(20px)" } : {}}
      >
        {t("hello")} {profile?.FirstName}{" "}
        {
          /*(profile?.Phone === "+972547997155" ||
          profile?.Phone === "+972547898398" ||
          profile?.Phone === "+972525813751" ||
          profile?.Phone === "+972537536392" ||
          profile?.Phone === "+972525291333" ||
          profile?.Phone === "+972545603120" ||
          profile?.Phone === "+972587897623" ||
          profile?.Phone === "+972528506862")*/ true && (
            <span>
              <img
                src={
                  points < 1001
                    ? ExpertDiamond
                    : points > 1000 && points < 2501
                    ? MasterDiamond
                    : points > 2500 && points < 5001
                    ? LegendDiamond
                    : ChampionDiamond
                }
                className="status-icon"
              />{" "}
              {points}
            </span>
          )
        }
      </span>
      {title && (
        <Title
          style={
            isIOS
              ? {
                  fontSize: title.length > 40 ? "14px" : "16px",
                  transform: "translateY(10px)",
                }
              : { fontSize: title.length > 40 ? "14px" : "16px" }
          }
          title={title}
          smallTitle={smallTitle ? smallTitle : ""}
          titleIcon={titleIcon}
        ></Title>
      )}
    </div>
  );
};
